import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export default function Contact({ item }) {
  return (
    <div className="contactBox">
      <div className="title">{item.title}</div>
      {item.address && <div className="text">{item.address}</div>}
      {item.location && (
        <div className="location">
          <GatsbyImage image={getImage(item.image)} alt="location" />
          <a className="locationText" href={item.link}>
            {item.location}
          </a>
        </div>
      )}
      {item.media && item.media.map(({ link, title }, i) => (
        <a href={link} key={i}>
          <div className="mediaTitle">{title}</div>
        </a>
      ))}
    </div>
  )
}
