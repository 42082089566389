import React, { useState, useEffect } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useForm } from 'react-hook-form'
import { useDropzone } from 'react-dropzone'
import ArrowLight from '../../../images/assets/line-light.svg'
import { mail } from '../../../config'
import getFormData from '../../../hooks/useMenuQuery'
import { getItemsByLocale } from '../../../utils'

export default function ContactForm({ locale }) {
  const [selectedFile, setSelectedFile] = React.useState(null)
  const [uploadedFile, setUploadedFile] = useState(true)
  const [submitSuccess, setSubmitSuccess] = useState()
  const { formData } = getFormData()
  const data = getItemsByLocale(locale, formData.edges)
  const form = data[0].node.frontmatter.form
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm()
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.png', '.jpeg', '.jpg', '.bmp'],
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-powerpoint': ['.ppt'],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
      'application/vnd.visio': ['.vsd'],
      'application/vnd.oasis.opendocument.text': ['.odt'],
      'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
      'application/vnd.oasis.opendocument.presentation': ['.odp'],
    },
    multiple: false,
    onDrop: files => {
      setUploadedFile(files[0].size < mail.fileSize)
      setValue('file', files)
    },
  })
  useEffect(() => {
    register('file')
  }, [register])
  useEffect(() => {
    setSelectedFile(acceptedFiles[0])
  }, [acceptedFiles])
  const SelectedFiles = () => (
    <>
      {selectedFile && (
        <div className="selectedFileBox">
          <div
            role="presentation"
            className="clearFile"
            onClick={() => {
              setSelectedFile(null)
              setUploadedFile(true)
            }}
          />
          <span>{selectedFile.path}</span>
        </div>
      )}
    </>
  )
  const appendFormData = data => {
    data.file = data.file && data.file[0]
    const formData = new FormData()
    for (let [key, value] of Object.entries(data)) {
      if (value) {
        formData.append(key, value)
      }
    }
    return formData
  }

  const onSubmit = data => {
    const body = appendFormData(data)
    fetch(mail.url, { method: 'POST', body })
      .then(() => setSubmitSuccess({ error: false }))
      .catch(error => {
        console.error(error)
        setSubmitSuccess({ error: true })
      })
  }
  return submitSuccess && !submitSuccess.error ? (
    <div className="successMessageBox">
      <p>{form.succes}</p>
    </div>
  ) : (
    <form className="formContainer" onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className="formField">
        <span className={errors.clientName && 'required'}>{form.name}*</span>
        <input
          className={`formLarge ${errors.clientName ? 'validationAlert' : ''}`}
          type="text"
          name="clientName"
          placeholder={form.nameTemplate}
          {...register('clientName', { required: true })}
        />
      </div>
      <div className="formField">
        <span>{form.company}</span>
        <input className="formLarge" type="text" name="companyName" {...register('companyName')} />
      </div>
      <div className="addressBox">
        <div className="emailBox">
          <span className={errors.email && 'required'}>{form.email}*</span>
          <input
            className={`formLarge ${errors.email ? 'validationAlert' : ''}`}
            type="email"
            name="email"
            placeholder={form.emailTemplate}
            {...register('email', {
              required: true,
              pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            })}
          />
        </div>
        <div className="phoneBox">
          <span>{form.phone}</span>
          <input className="formLarge" type="text" name="phone" {...register('phone')} />
        </div>
      </div>
      <div className="formField">
        <span>{form.message}</span>
        <textarea
          name="message"
          className={`formLarge formTextarea ${errors.message ? 'validationAlert' : ''}`}
          {...register('message')}
        />
      </div>
      <div className="formField">
        <div className="uploadFileBox">
          <div className="uploadFileLabel">{form.download}</div>

          <div className={'uploadFileIcon'}>
            <GatsbyImage className="desktop" image={getImage(form.image1)} alt="location" />
            <GatsbyImage className="mobile" image={getImage(form.image2)} alt="location" />
          </div>
        </div>
        <SelectedFiles />
        {!selectedFile && (
          <>
            <label className="dropzoneBox" {...getRootProps({ onClick: e => e.preventDefault() })}>
              <p>{form.brief}</p>
              <div>
                <p>
                  (.png, .jpg, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .vsd, .odt, .ods, .odp)
                </p>
              </div>
              <input type="file" name="file" {...getInputProps()} />
            </label>
            {errors.file && <span className="errorMessage">{errors.file.message}</span>}
          </>
        )}
        {!uploadedFile && (
          <span className="required">{`${form.fileSize} ${mail.fileSize / 1000000} MB`}</span>
        )}
        {submitSuccess &&
          (!submitSuccess.error ? (
            <span className="success">{form.succes}</span>
          ) : (
            <span className="required">{form.error}</span>
          ))}
      </div>
      <div className="formButton">
        <button disabled={!uploadedFile} className="button btn-dark" type="submit">
          {form.send}&nbsp;
          <ArrowLight />
        </button>
      </div>
    </form>
  )
}
