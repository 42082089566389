import React from 'react'
import { graphql } from 'gatsby'
import LayoutHome from '../components/layout/LayoutHome'
import Contact from '../components/pages/contacts/Contact'
import ContactForm from '../components/pages/contacts/ContactForm'

const ContactsPage = ({ location, data, pageContext }) => {
  const { formDescription, formTitle, title, contacts } = data.markdownRemark.frontmatter
  return (
    <LayoutHome
      location={location}
      locale={pageContext.locale}
      locationPath={pageContext.locationPath}
    >
      <div className="contactsPageContainer">
        <h1 className="pageTitle">{title}</h1>
        <div className="contactsList">
          {contacts.map((item, i) => (
            <Contact item={item} key={i} />
          ))}
        </div>
        <div className="formSection">
          <div className="newProject" id="formDescription">
            <div className="rigthBox">
              <h1>{formTitle}</h1>
            </div>
            <div className="leftBox">
              <p>{formDescription}</p>
            </div>
          </div>
          <ContactForm locale={pageContext.locale} />
        </div>
      </div>
    </LayoutHome>
  )
}
export default ContactsPage

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        formDescription
        formTitle
        title
        contacts {
          address
          location
          title
          link
          media {
            link
            title
          }
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`
